import React from "react";
import * as styles from "./hero.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Character from "../components/assets/pixeltrue-idea.svg";

const Hero = () => {
  return (
    <div className={styles.heroContainer}>
      <div className={styles.heroWrapper}>
        <div className={styles.textBox}>
          <div className={styles.greeting}>
            <h1>H e l l o !</h1>
          </div>

          <p>
            {
              "I’m a software engineer who focuses on web development. I currently work\
               a lot with React, AWS cloud solutiuons, and Python. But I’m passionate about learning\
            new things and improving the skills I already have, which is what the blog part of this\
            website is all about. Hopefully you get something out\
            of my little corner of the web."
            }
          </p>
          <div className={styles.socialIcons}>
            <a
              href="https://www.linkedin.com/in/hdreikorn/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="linkedin link"
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" />
            </a>
            <a
              href="https://github.com/HDreikorn"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="github link"
            >
              <FontAwesomeIcon icon={["fab", "github"]} size="2x" />
            </a>
            <a
              href="https://twitter.com/hdreikorn"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram link"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} size="2x" />
            </a>
          </div>
        </div>
        <Character />
      </div>
    </div>
  );
};

export default Hero;
